@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-size: 14px;
  font-family: "Saira Semi Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: "Saira Semi Condensed", sans-serif;
}

i {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: "Saira Semi Condensed", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App.css */
.shimmer-mask::before,
.shimmer-mask::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  mix-blend-mode: color;
  opacity: 0;
  transition: all 0.5s ease;
}

.shimmer-mask::before {
  box-shadow: 0 0 3px 2px hsl(222, 20%, 95%), 0 0 7px 4px hsl(222, 20%, 80%),
    0 0 13px 4px hsl(222, 50%, 70%), 0 0 25px 5px hsl(222, 100%, 70%);
  animation: shine 1.2s ease-in-out infinite;
}

.shimmer-mask::after {
  box-shadow: inset 0 0 0 1px hsl(222, 70%, 95%),
    inset 0 0 2px 1px hsl(222, 100%, 80%), inset 0 0 5px 2px hsl(222, 100%, 70%);
}
